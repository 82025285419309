/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure - 404 page.
 */

// Core dependencies
import { Link } from "gatsby";
import React from "react";

// App dependencies
import Layout from "../components/layout";

// Styles
import { content } from "../components/markdown/markdown.module.css";
import { container } from "../templates/contentTemplate.module.css";

let classNames = require("classnames");

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className={classNames(container, content)}>
          <h1>Page Not Found</h1>
          <p>We can’t find the page you were looking for.</p>
          <p>Here are some helpful links instead:</p>
          <p>
            <Link to="/">Home Page</Link>
          </p>
          <p>
            <Link to="/contact">Contact</Link>
          </p>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
